import NavBar from '../../components/NavBar/NavBar.js';
import './About.css';

function About() {
  return (
    <>
      <NavBar />
      <div className="page-container page-container-padding-top">
        <h1 className="centered">Padl</h1>
        <h2 className="centered">Introducing Padl – the companion app for padel tennis</h2>
        <h2 className="centered">Available on iOS and Apple Watch</h2>
        <p>
How many times have you argued with your friends about the scores of your padel game? Well, you won't have to again. With Padl, you can easily track your scores with a quick glance and a tap on your wrist!

Features

<ul>
<li>Keep track of scores in realtime.</li>
<li>Track your workout session.</li>
<li>See all your previously played games, with statistics. </li>
<li>Browse nearby padel centers. With easy access to book your next session.</li>
<li>Invite friends to your matches.</li>
<li>See padel rules.</li>
</ul>

        </p>
      </div>
    </>
  );
}

export default About;
