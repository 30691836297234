import NavBar from '../components/NavBar/NavBar.js';
import './Instaradio.css';
import InstaradioMac from "../resources/instaradio-mac.png";

function Instaradio() {
  return (
    <>
      <NavBar />
      <div className="page-container page-container-padding-top">
        <img src={InstaradioMac} alt="Instaradio Logo"/>
        <h1>Instaradio</h1>
        <p>Instaradio is no longer available starting April 24, 2021. Instaradio was originally launched back in 2012, but is no longer under development. I’m extremely grateful for making a product so many people have enjoyed.</p>
      </div>
    </>
  );
}

export default Instaradio;
