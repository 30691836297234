import NavBar from '../../components/NavBar/NavBar.js';
import './BalanseTerms.css';

function BalanseTerms() {
  return (
    <>
      <NavBar />
      <div className="page-container page-container-padding-top">
        <h1 className="centered">Terms of Use</h1>
<p>
        These Terms of Use "Agreement" govern your access and use of the Balanse mobile application ("App"). By using the App, you agree to be bound by this Agreement.

User Responsibilities:
a. You must not use the App for any illegal or unauthorized purposes.
b. You must not interfere with or disrupt the App's operation or its servers.

Intellectual Property:
a. All intellectual property rights related to the App and its content are owned by Balanse.
b. You may not copy, modify, distribute, or reproduce any part of the App without prior written permission.
Privacy:
a. The App may collect and use personal information as described in our Privacy Policy.
b. By using the App, you consent to the collection and use of your personal information.
Disclaimer:
a. The App is provided on an "as-is" basis, without warranties of any kind, either expressed or implied.
b. Balanse does not guarantee the accuracy, reliability, or availability of the App.
Limitation of Liability:
a. Balanse shall not be liable for any direct, indirect, incidental, or consequential damages arising from your use of the App.
b. Balanse shall not be responsible for any errors, omissions, or inaccuracies in the App's content.
Termination:
a. Balanse reserves the right to terminate or suspend your access to the App at any time without notice.
Governing Law:
a. This Agreement shall be governed by and construed in accordance with the laws of Norway.
Modifications:
a. Balanse reserves the right to modify or update this Agreement at any time. Your continued use of the App after any changes indicates your acceptance of the modified Agreement.
</p>
      </div>
    </>
  );
}

export default BalanseTerms;
