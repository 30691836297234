import NavBar from '../components/NavBar/NavBar.js';
import './Home.css';

function Home() {
  return (
    <>
      <NavBar />
      <div className="page-container page-container-padding-top">
      </div>
    </>
  );
}

export default Home;
