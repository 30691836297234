import NavBar from "../components/NavBar/NavBar.js";

function ErrorPage() {
  return (
    <>
      <NavBar />
      <div className="page-container page-container-padding-top">
        <h1>Something went wrong.</h1>
        <p>Could not load the requested page.</p>
      </div>
    </>
  );
}

export default ErrorPage;