import { Link } from 'react-router-dom';
import logo from '../../resources/logo-soriko.png';
import './NavBar.css';

function NavBar() {
  return (
    <nav>
      <Link to="/">
        <img src={logo} alt="Soriko Logo" className="logo" />
      </Link>
      <ul className="nav-links">
        <li className="nav-item"><Link to="/">Soriko</Link></li>
      </ul>
    </nav>
  );
}

export default NavBar;
