import NavBar from '../../components/NavBar/NavBar.js';
import './BalansePrivacyPolicy.css';

function BalansePrivacyPolicy() {
  return (
    <>
      <NavBar />
      <div className="page-container page-container-padding-top">
        <h1 className="centered">Privacy Policy</h1>
        <h2 className="centered">
        Balanse is an app available for free on the App Store.

        The app offers optional paid purchase. We use RevenueCat to handle subscriptions.

        What we collect:
        - The only thing we collect is data collected by RevenueCat. RevenueCat collects purchase history from users.

        That's it.
        </h2>
      </div>
    </>
  );
}

export default BalansePrivacyPolicy;
