import NavBar from '../../components/NavBar/NavBar.js';
import './PadlPrivacyPolicy.css';

function PadlPrivacyPolicy() {
  return (
    <>
      <NavBar />
      <div className="page-container page-container-padding-top">
        <h1 className="centered">Privacy Policy</h1>
        <h2 className="centered">
        Padl is an app available for free on the App Store.
        </h2>

      <p>
        - You can register for a user account in the app.
        - You can also share your matches with other players. 
        - We do not track your personal details.
        </p>
      </div>
    </>
  );
}

export default PadlPrivacyPolicy;
