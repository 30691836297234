import { Switch, Route } from "react-router";
import ErrorPage from "./pages/ErrorPage.js";
import Home from "./pages/Home.js";
import Instaradio from "./pages/Instaradio.js";
import BalanseTerms from "./pages/Balanse/BalanseTerms.js";
import BalansePrivacyPolicy from "./pages/Balanse/BalansePrivacyPolicy.js";
import PadlTerms from "./pages/Padl/PadlTerms.js";
import PadlPrivacyPolicy from "./pages/Padl/PadlPrivacyPolicy.js";
import About from "./pages/Padl/About.js";

function App() {
  return (
    <main>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/instaradio" component={Instaradio} />
        <Route path="/balanse/terms" component={BalanseTerms} />
        <Route path="/balanse/privacy-policy" component={BalansePrivacyPolicy} />
        <Route path="/padl" component={About} />
        <Route path="/padl/terms" component={PadlTerms} />
        <Route path="/padl/privacy-policy" component={PadlPrivacyPolicy} />
        <Route component={ErrorPage} />
      </Switch>
    </main>
  );
}

export default App;
